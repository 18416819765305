<template>
  <div class="gp-runs">
    <gp-filter
      v-if="!compact"
      v-model="filter"
      stream="combined"
      :groups="['optimizations', 'search', 'reference-date']"
      :source="{
        dims: ['item', 'target_price_zone as price_zone'],
        links: [{
          linkName: 'optimization',
          sourceName: 'optimization',
          columnPairs: [{
            srcColumn: 'item',
            dstColumn: 'item',
          }, {
            srcColumn: 'price_zone',
            dstColumn: 'price_zone',
          }],
        }],
      }"
      :vars="vars"
      :filter2="usersFilter"
      expand="optimization"
      :attributes="[{
        name: l10n('Run name'),
        calc: 'optimization.strategy_name',
      }, {
        name: l10n('Started by'),
        calc: 'optimization.create_user',
      }]"
      :popupPortal="null"
    />
    <plain-table
      id="optimizations"
      ref="table"
      stream="combined"
      :freezeDims="true"
      :groups="['optimizations', 'search', 'reference-date']"
      :source="{
        filter0: datesFilter,
        filter1: datesFilter,
        dims: ['item', 'target_price_zone as price_zone'],
        links: [{
          linkName: 'optimization',
          sourceName: 'optimization',
          columnPairs: [{
            srcColumn: 'item',
            dstColumn: 'item',
          }, {
            srcColumn: 'price_zone',
            dstColumn: 'price_zone',
          }],
        }, {
          linkName: 'optimization_summary',
          sourceName: 'optimization_summary',
          columnPairs: [{
            srcColumn: 'optimization.optimization_run_id',
            dstColumn: 'optimization_run_id',
          }],
        }, {
          linkName: 'optimization_goals',
          sourceName: 'optimization_goals',
          columnPairs: [{
            srcColumn: 'optimization.optimization_run_id',
            dstColumn: 'optimization_run_id',
          }],
        }],
      }"
      :filter2="filter2"
      :filter3="filter3"
      :dims="dims"
      :vals="vals"
      :cols="cols"
      :vars="vars"
      :initialSort="sort"
      :initialTake="take"
      :clientSort="false"
      :prefetchSize="1"
      expand="optimization"
      @link-clicked="linkClicked"
      @report-updated="report = $event"
      :tableActions="tableActions"
      :isOptimizationsTable="true"
    />
  </div>
</template>
<script>
const utils = require('../my-utils');

const formatTime = (x) => new Date(x).toLocaleString();

const formatNumber = (x) => (x ? new Number(x).toLocaleString(this.locale) : '-');

const formatPercent = (x) => (x ? new Number(x).toLocaleString(this.locale, {
  style: 'percent',
  maximumFractionDigits: 1,
}) : '-');

module.exports = {
  mixins: [
    utils.extraFilters,
    utils.configHelpers,
  ],
  props: {
    bounds: { type: String },
    compact: { type: Boolean, default: false },
    locale: { type: String },
    username: { type: String },
    sections: { type: Array },
    filter3: { type: String },
    referenceDate: { type: String },
    pastTimeframe: { type: String },
    futureTimeframe: { type: String },
    dims: {
      type: Array,
      default: () => [{
        name: ' ',
        calc: 'optimization.optimization_run_id',
        actionable: true,
        actionlink: 'javascript:void(0)',
        format: () => utils.l10n('choose'),
        section: 'Dimensions',
      }, {
        name: 'Run name',
        calc: 'optimization.strategy_name',
        section: 'Dimensions',
        title: '(x) => x',
      }],
    },
    vals: {
      type: Array,
      default: () => [{
        name: 'Permalink',
        calc: 'optimization_summary.permalink',
        section: 'Optimizations',
        format: (x) => x.split('permalink=')[1],
        actionlink: (row, col) => row[col.i],
      }, {
        name: 'Started by',
        calc: 'optimization.create_user',
        section: 'Optimizations',
      }, {
        name: 'Started on',
        calc: 'optimization.create_time',
        format: formatTime,
        section: 'Optimizations',
      }, {
        name: 'Price changes',
        calc: 'optimization_summary.price_changes',
        format: formatNumber,
        section: 'Optimizations',
      }, {
        name: 'Prices increased',
        calc: 'optimization_summary.price_increased',
        format: formatNumber,
        section: 'Optimizations',
      }, {
        name: 'Prices decreased',
        calc: 'optimization_summary.price_decreased',
        format: formatNumber,
        section: 'Optimizations',
      }, {
        name: 'Average price increase',
        calc: 'optimization_summary.price_avg_increase',
        format: formatPercent,
        section: 'Optimizations',
      }, {
        name: 'Average price decrease',
        calc: 'optimization_summary.price_avg_decrease',
        format: formatPercent,
        section: 'Optimizations',
      }],
    },
    vars: { type: Object },
    sort: { type: Array, default: () => [-5] },
    take: { type: Number, default: 20 },
  },
  data() {
    return {
      filter: [],
      report: null,
      values: {},
    };
  },
  mounted() {
    this.mounted = true;
    this.activeQueries = new Set();
  },
  beforeDestroy() {
    this.mounted = false;
    for (const queryId in [...this.activeQueries]) {
      utils.bridge.trigger('cancelReport', queryId);
    }
  },
  watch: {
    async rows() {
      const { rows } = this;
      const { table } = this.$refs;
      const tasks = [];
      for (let row of rows) {
        const { key } = row;
        const values = this.values[row[0]];

        if (values) {
          row = _.clone(row);
          row.__cache = {};
          row.splice(this.offset, row.length, ...values);
          Vue.set(table.rowOverrides, key, row);
          continue;
        }

        const query = _.cloneDeep(this.query);

        query.id = utils.randomId();
        query.source.dims = _.map(query.source.dims, 'calc');
        query.source.vals = _.map(query.source.vals, 'calc');
        query.source.cols = _.map(query.source.cols, 'calc');

        query.source.filter0 = this.makeFilter(
          [query.source.filter0, this.$refs.table.extraFilter0],
        );

        query.source.filter1 = this.makeFilter(
          [query.source.filter1, this.$refs.table.extraFilter1],
        );

        query.source.filter2 = this.makeFilter(
          [query.source.filter2, this.$refs.table.extraFilter2],
        );

        query.dims = _.map(query.dims, 'calc');
        query.vals = _.map(query.vals, 'calc');
        query.cols = _.map(query.cols, 'calc');

        query.vars = _.assign({}, this.vars, {
          target_optimization_run_id: utils.quote(row[0]),
        });
        tasks.push({ row, key, query });
      }
      for (const chunk of _.chunk(tasks, 10)) {
        if (!this.mounted || rows !== this.rows) {
          return;
        }

        await Promise.all(chunk.map(({ row, key, query }) => {
          this.activeQueries.add(query.id);
          return utils.query(query)
            .then(([values]) => {
              if (!this.mounted || rows !== this.rows) {
                return;
              }
              row = _.clone(row);
              row.__cache = {};
              row.splice(this.offset, row.length, ...values);
              Vue.set(table.rowOverrides, key, row);
              this.$set(this.values, row[0], values);
              this.activeQueries.delete(query.id);
            })
            .catch(() => {
              this.activeQueries.delete(query.id);
            });
        }));
      }
    },
  },
  methods: {
    l10n(text) {
      return utils.l10n(text);
    },
    linkClicked(e, info) {
      if (info.column.i == 0) {
        this.$emit('click', info.row[info.column.i]);
      }
    },
    makeFilter(filters) {
      return _(filters)
        .filter()
        .map((filter) => `(${filter})`)
        .join(' && ');
    },
    columnName(column) {
      return column.name || this.metricsByFormula[column.formula]?.name;
    },
    resolveTimeframe(metric, column) {
      let timeframe = column.timeframe || metric.timeframe;

      if (timeframe == 'past') {
        timeframe = this.pastTimeframe;
      }

      if (timeframe == 'future') {
        timeframe = this.futureTimeframe;
      }

      if (!this.timeframes[timeframe]) {
        timeframe = 'reference_date';
      }

      return timeframe;
    },
    makeVals(vals, metric, column, section) {
      const referenceDate = utils.parseDate(this.referenceDate);
      const timeframe = this.resolveTimeframe(metric, column);

      const [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate);

      const resolveSubstitutes = (calc, depth = 0) => {
        if (depth == 10) {
          return calc;
        }
        return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
          const formula = this.formulas[symbol];
          if (formula !== undefined && !this.isAggregationFormula(formula)) {
            return `(${resolveSubstitutes(formula, depth + 1)})`;
          }
          return symbol;
        });
      };

      const registerFormula = (symbol) => {
        const formula = this.formulas[symbol];
        if (formula !== undefined) {
          if (this.isAggregationFormula(formula)) {
            vals[`${symbol}_${timeframe}`] = this.resolveDateConditions(
              resolveSubstitutes(formula),
              startDate,
              endDate,
              referenceDate,
            );
          } else {
            for (const [symbol] of formula.matchAll(/[a-zA-Z_][a-zA-Z_0-9]*/g)) {
              registerFormula(symbol);
            }
          }
        }
      };

      const symbols = metric.formula.split(/[\s,]+/g);
      for (const symbol of symbols) {
        registerFormula(symbol);
      }
    },
    makeCols(cols, metric, column, section) {
      let calc;
      const symbols = metric.formula.split(/[\s,]+/g);
      const symbol = symbols[0];

      const formula = this.formulas[symbol];

      const timeframe = this.resolveTimeframe(metric, column);

      if (formula !== undefined) {
        if (this.isAggregationFormula(formula)) {
          calc = `${symbol}_${timeframe}`;
        } else {
          const resolveSubstitutes = (calc, depth = 0) => {
            if (depth == 10) {
              return calc;
            }
            return calc.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => {
              const formula = this.formulas[symbol];
              if (formula !== undefined) {
                if (this.isAggregationFormula(formula)) {
                  return `${symbol}_${timeframe}`;
                }
                return `(${resolveSubstitutes(formula, depth + 1)})`;
              }
              return symbol;
            });
          };
          calc = formula.replaceAll(/[a-zA-Z_][a-zA-Z_0-9]*/g, (symbol) => resolveSubstitutes(symbol));
        }
      }

      const name = column.name || metric.name;

      let format = this.formats[metric.format] || metric.format;
      if (_.isString(format)) {
        try {
          format = eval(format);
        } catch (ex) {
          console.warn(format, ex);
        }
      }
      if (!_.isFunction(format)) {
        format = (x) => x;
      }

      const { style } = column;

      if (calc !== undefined) {
        cols.push(_.assign(
          {
            name,
            calc,
            style,
            format,
            metric,
            section: section.name,
          },
          _.omit(metric, ['name', 'type', 'format']),
        ));
      }
    },
    async exportToExcel() {
      const XLSX = await import('xlsx');
      const workbook = XLSX.utils.book_new();
      const { table } = this.$refs;
      const rows = [];
      rows.push(table.columns.map((column) => utils.l10n(column.name)));
      for (let row of table.rows) {
        row = table.rowOverrides[row.key] || row;
        rows.push(table.columns.map((column) => column.format(row[column.i])));
      }
      const worksheet = XLSX.utils.aoa_to_sheet(rows);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'report');
      XLSX.writeFile(workbook, 'optimizations.xlsx');
    },
  },
  computed: {
    tableActions() {
      return [{
        icon: 'download',
        text: 'Download as Excel',
        call: () => {
          this.exportToExcel(); return true;
        },
        menu: true,
      }];
    },
    cols() {
      return this.columns.map((column) => ({
        calc: '0.0',
        name: column.name,
        style: column.style,
        format: column.format,
        section: column.section,
      }));
    },
    offset() {
      return this.dims.length + this.vals.length;
    },
    usersFilter() {
      return `(optimization.create_user == '${this.username}')`;
    },
    filter2() {
      const filter2 = [];
      filter2.push(this.usersFilter);
      for (const condition of this.filter) {
        for (const key of _.keys(condition)) {
          const value = condition[key];
          if (key && value) {
            filter2.push(`(${key}) in ${utils.quote(value)}`);
          }
        }
      }
      return filter2.join(' && ');
    },
    columns() {
      return _.map(this.query.source.cols);
    },
    rows() {
      return this.report?.rows || [];
    },
    datesFilter() {
      const dates = new Set();
      const referenceDate = utils.parseDate(this.referenceDate);
      for (const date of [
        referenceDate,
        utils.nextDate(referenceDate),
        utils.prevDate(referenceDate)]) {
        dates.add(utils.formatDate(date));
      }
      for (const section of this.sections) {
        for (const column of section.columns) {
          const metric = this.metricsByFormula[column.formula];
          if (metric) {
            let { timeframe } = metric;
            if (!this.timeframes[timeframe]) {
              timeframe = 'reference_date';
            }
            let [startDate, endDate] = eval(this.timeframes[timeframe].calc)(referenceDate);
            if (endDate >= startDate) {
              if (metric.formula) {
                const formula = this.resolveSubstitutes(metric.formula);
                if (_.includes(formula, 'date_before_start')) {
                  startDate = utils.prevDate(startDate);
                }
                if (_.includes(formula, 'date_after_end')) {
                  endDate = utils.nextDate(endDate);
                }
              }
              let date = new Date(startDate);
              while (date.getTime() <= endDate.getTime()) {
                dates.add(utils.formatDate(date));
                date = utils.nextDate(date);
              }
            }
          }
        }
      }
      const datesArray = Array.from(dates).sort((a, b) => new Date(a) - new Date(b));
      const datesLength = datesArray.length;
      const [firstDate] = datesArray;

      if (datesLength > 1) {
        const lastDate = datesArray[datesLength - 1];

        return `date >= \`${firstDate}\` && date <= \`${lastDate}\``;
      }

      return `date == \`${firstDate}\``;
    },
    query() {
      const vals = {};
      const cols = [];
      const dims = []; // this.primaryDims
      for (const section of this.sections) {
        for (const column of section.columns) {
          const metric = this.metricsByFormula[column.formula];
          if (metric) {
            this.makeVals(vals, metric, column, section);
            this.makeCols(cols, metric, column, section);
          }
        }
      }
      return {
        stream: 'combined',
        source: {
          filter0: this.datesFilter,
          dims,
          cols,
          vals: _(vals)
            .toPairs()
            .map(([name, calc]) => ({
              name,
              calc: `${calc} as ${name}`,
              show: false,
            }))
            .sortBy('calc')
            .value(),
        },
        vals: _.map(
          cols,
          ({ metric, format, calc }, i) => {
            let aggregation = 'sum';
            if (metric.format == 'percent') {
              aggregation = 'avg';
            }

            if (metric.formula.startsWith('avg')) {
              aggregation = 'avg';
            }

            if (_.startsWith(calc, 'avg')) {
              aggregation = 'avg';
            }
            if (_.startsWith(calc, 'min')) {
              aggregation = 'min';
            }
            if (_.startsWith(calc, 'max')) {
              aggregation = 'max';
            }

            let precision;
            if (aggregation == 'sum') {
              precision = 0;
            }

            const col = `col${i + dims.length + 1}`;

            return {
              metric,
              format,
              precision,
              calc: `${aggregation}(${col} if ${col} != 0)`,
            };
          },
        ),
      };
    },
  },
};
</script>
<style>
.gp-runs .table {
    font-size: 0.9em;
}
.gp-runs .table td {
    white-space: nowrap;
}
.gp-runs .table tr:last-child th:nth-child(n+5) {
    text-align: right;
}
.gp-runs .table td:nth-child(n+5) {
    text-align: right;
}
.gp-runs .table th:nth-child(n+11) a {
    pointer-events: none;
    color: inherit;
    text-decoration: none!important;
}
.gp-runs .feather-icon-download {
    display: none;
}
.gp-runs .plain-table-slider {
    margin: 0;
}
.gp-runs th.my-column-dim {
    background-color: white;
}
.my-dark-theme .gp-runs th.my-column-dim {
    background-color: #303030;
}
.gp-runs .plain-table-body {
    margin-left: -16px;
    margin-right: -16px;
}
.gp-runs .table {
    padding-left: 16px!important;
    padding-right: 16px!important;
}
</style>
