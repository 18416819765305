var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-items" },
    [
      _c("div", { staticClass: "pim-items-body" }, [
        _c(
          "div",
          { staticClass: "pim-items-main" },
          [
            _c(
              "div",
              { staticClass: "pim-items-main-head" },
              [
                _c("chosen", {
                  attrs: {
                    stream: "categories",
                    dims: ["brand.id"],
                    vals: ["brand.name"],
                    placeholder: "Storefront",
                    multiple: true,
                    initialSelection: _vm.brands,
                    format: function(x, row) {
                      return row[1]
                    }
                  },
                  on: {
                    change: function($event) {
                      _vm.brands = $event
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("chosen", {
                      ref: "categories",
                      attrs: {
                        stream: "categories",
                        dims: ["id"],
                        vals: ["name"],
                        placeholder: "Category",
                        multiple: true,
                        initialSelection: _vm.brands,
                        format: function(x, row) {
                          return row[1]
                        }
                      },
                      on: {
                        change: function($event) {
                          _vm.categories = $event
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary btn-sm",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            _vm.browseCategories = true
                          }
                        }
                      },
                      [_vm._v("\n            Browse\n          ")]
                    ),
                    _vm._v(" "),
                    _vm.browseCategories
                      ? _c("fancytree", {
                          attrs: {
                            rootKey: "",
                            placeholder: "Category",
                            stream: "categories",
                            catalog: "categories",
                            keyColumn: "id",
                            groupColumn: "parent_id",
                            labelColumn: "name",
                            isGroupColumn: "has_children",
                            showInput: false
                          },
                          on: {
                            submit: function($event) {
                              _vm.browseCategories = false
                              _vm.fancytreeSubmit($event)
                            },
                            change: function($event) {
                              _vm.browseCategories = false
                              _vm.fancytreeSubmit($event)
                            },
                            cancel: function($event) {
                              _vm.browseCategories = false
                            },
                            clickoutside: function($event) {
                              _vm.browseCategories = false
                            }
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _vm._v(" "),
            _c("gp-table", {
              ref: "table",
              attrs: {
                product: "pim",
                stream: "items",
                groups: ["pim-items", "pim-items-search"],
                attributes: _vm.attributes,
                metrics: _vm.metrics,
                formulas: _vm.formulas,
                formats: _vm.formats,
                timeframes: _vm.timeframes,
                username: _vm.username,
                filter2: _vm.filter2
              },
              on: {
                "row-selected": _vm.rowSelected,
                "rows-selected": _vm.rowsSelected
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "pim-items-side" }, [
          _c("div", { staticClass: "pim-items-side-tabs" }, [
            _c("ul", [
              _c("li", { class: { active: _vm.mode == "settings" } }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.mode = "settings"
                      }
                    }
                  },
                  [_c("span", [_vm._v("Settings")])]
                )
              ]),
              _vm._v(" "),
              _c("li", { class: { active: _vm.mode == "attributes" } }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.mode = "attributes"
                      }
                    }
                  },
                  [_c("span", [_vm._v("Attributes")])]
                )
              ]),
              _vm._v(" "),
              _c("li", { class: { active: _vm.mode == "assignment" } }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.mode = "assignment"
                      }
                    }
                  },
                  [_c("span", [_vm._v("Assignment")])]
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pim-items-side-main" },
            [
              _c("portal-target", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.mode == "settings",
                    expression: "mode == 'settings'"
                  }
                ],
                attrs: { name: "gp-settings" }
              }),
              _vm._v(" "),
              _vm.mode == "attributes"
                ? [
                    _c("h2", [_vm._v("Attributes")]),
                    _vm._v(" "),
                    !_vm.entries.length
                      ? _c("p", [
                          _vm._v(
                            "\n            Please select lines on the left side to show attributes.\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("pim-attributes", {
                      attrs: {
                        stream: "items",
                        idColumn: "key",
                        search: _vm.search,
                        fields: _vm.fields,
                        focus: _vm.focus,
                        entries: _vm.entries,
                        username: _vm.username
                      }
                    })
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.mode == "assignment"
                ? [
                    _c("h2", [_vm._v("Assignment")]),
                    _vm._v(" "),
                    !_vm.entries.length
                      ? _c("p", [
                          _vm._v(
                            "\n            Please select lines on the left side to show attributes.\n          "
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("plain-table", {
                      attrs: {
                        stream: _vm.assignmentConfig.stream,
                        groups: _vm.assignmentConfig.groups,
                        filter0: _vm.assignmentConfig.filter0,
                        filter1: _vm.assignmentConfig.filter1,
                        filter2: _vm.assignmentConfig.filter2,
                        dims: _vm.assignmentConfig.dims,
                        vals: _vm.assignmentConfig.vals,
                        cols: _vm.assignmentConfig.cols
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        ])
      ]),
      _vm._v(" "),
      _c("gp-data", {
        attrs: {
          stream: "fields",
          filter2:
            "api_group_name in ['Item Attribute', 'Item Digital Asset Flex Fields', 'Variation Detail', 'Variation Flex Fields']",
          dims: ["api_name"],
          vals: ["field_name", "field_type"]
        },
        model: {
          value: _vm.fieldsReport,
          callback: function($$v) {
            _vm.fieldsReport = $$v
          },
          expression: "fieldsReport"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }