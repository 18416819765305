var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pim-columns" },
    [
      _c("my-search", {
        ref: "search",
        attrs: { autofocus: _vm.autofocus },
        model: {
          value: _vm.searchString,
          callback: function($$v) {
            _vm.searchString = $$v
          },
          expression: "searchString"
        }
      }),
      _vm._v(" "),
      _c(
        "ul",
        [
          _vm._l(_vm.currentColumns, function(entry) {
            return _c(
              "li",
              [
                _c(
                  "gp-check",
                  {
                    key: entry.item.id,
                    attrs: { checked: true },
                    on: {
                      change: function($event) {
                        return _vm.removeColumn(entry.item)
                      }
                    }
                  },
                  _vm._l(
                    _vm.formatSearchItem(entry.item.name, entry.matches),
                    function(part) {
                      return _c("span", { class: { matched: part.matched } }, [
                        _vm._v(_vm._s(part.text))
                      ])
                    }
                  ),
                  0
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm._l(_vm.visibleColumnCandidates, function(entry) {
            return _c(
              "li",
              [
                _c(
                  "gp-check",
                  {
                    key: entry.item.name,
                    attrs: { checked: false },
                    on: {
                      change: function($event) {
                        return _vm.addColumn(entry.item)
                      }
                    }
                  },
                  _vm._l(
                    _vm.formatSearchItem(entry.item.name, entry.matches),
                    function(part) {
                      return _c("span", { class: { matched: part.matched } }, [
                        _vm._v(_vm._s(part.text))
                      ])
                    }
                  ),
                  0
                )
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.visibleColumnCandidates.length < _vm.columnCandidates.length
            ? _c("li", [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function($event) {
                        _vm.showMore = true
                      }
                    }
                  },
                  [_c("l10n", { attrs: { value: "Show more..." } })],
                  1
                )
              ])
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pim-columns-actions" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-primary",
            attrs: { type: "button", disabled: !_vm.somethingChanged },
            on: {
              click: function($event) {
                return _vm.$emit("submit", _vm.changes.columns)
              }
            }
          },
          [_c("l10n", { attrs: { value: "OK" } })],
          1
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-sm btn-secondary",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("cancel")
              }
            }
          },
          [_c("l10n", { attrs: { value: "Cancel" } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("hr")])
  }
]
render._withStripped = true

export { render, staticRenderFns }