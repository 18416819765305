var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pim-function" }, [
    _vm.collaborators.children
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Function name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticClass: "form-control form-control-sm",
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          })
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.collaborators.children
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Collaborators")]),
            _vm._v(" "),
            _c("pim-select", {
              attrs: {
                options: _vm.users.map(function(user) {
                  return { name: user.first_name + " " + user.last_name }
                }),
                multiple: true
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c(
          "gp-check",
          {
            model: {
              value: _vm.autoApprove,
              callback: function($$v) {
                _vm.autoApprove = $$v
              },
              expression: "autoApprove"
            }
          },
          [_vm._v("\n      Auto approve\n    ")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Required days to complete")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.daysToComplete,
            expression: "daysToComplete"
          }
        ],
        staticClass: "form-control form-control-sm",
        attrs: { type: "number" },
        domProps: { value: _vm.daysToComplete },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.daysToComplete = $event.target.value
          }
        }
      })
    ]),
    _vm._v(" "),
    !_vm.autoApprove
      ? _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v("Approvers")]),
            _vm._v(" "),
            _c("pim-select", {
              attrs: {
                options: _vm.users.map(function(user) {
                  return { name: user.first_name + " " + user.last_name }
                }),
                multiple: true
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("label", [_vm._v("Item validation")]),
        _vm._v(" "),
        _c("pim-filter", { attrs: { fields: _vm.fields, entryType: "item" } }),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("label", [_vm._v("Variation validation")]),
        _vm._v(" "),
        _c("pim-filter", {
          attrs: { fields: _vm.fields, entryType: "variation" }
        }),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("label", [_vm._v("SKU validation")]),
        _vm._v(" "),
        _c("pim-filter", { attrs: { fields: _vm.fields, entryType: "sku" } })
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "pim-function-actions" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-primary",
          attrs: { type: "button" },
          on: { click: _vm.submit }
        },
        [_vm._v("\n      Submit changes\n    ")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-secondary",
          attrs: { type: "button" },
          on: { click: _vm.discard }
        },
        [_vm._v("\n      Discard changes\n    ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }