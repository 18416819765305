<template>
  <div class="pim-function">
    <div class="form-group" v-if="collaborators.children">
      <label>Function name</label>
      <input
        v-model="name"
        class="form-control form-control-sm"
      />
    </div>
    <!--div class="form-group" v-if="collaborators.children">
            <label>Storefronts</label>
            <pim-select :options="storefronts" :multiple="true"/>
            <gp-check v-for="storefront in storefronts">
                {{storefront.name}}
            </gp-check>
        </div-->
    <div class="form-group" v-if="collaborators.children">
      <label>Collaborators</label>
      <pim-select
        :options="users.map(user => ({ name: `${user.first_name} ${user.last_name}` }))"
        :multiple="true"
      />
      <!--gp-check v-for="user in users">
                {{user.first_name}} {{user.last_name}}
            </gp-check-->
    </div>
    <div class="form-group">
      <gp-check v-model="autoApprove">
        Auto approve
      </gp-check>
    </div>
    <div class="form-group">
      <label>Required days to complete</label>
      <input
        v-model="daysToComplete"
        type="number"
        class="form-control form-control-sm"
      />
    </div>
    <div class="form-group" v-if="!autoApprove">
      <label>Approvers</label>
      <pim-select
        :options="users.map(user => ({ name: `${user.first_name} ${user.last_name}` }))"
        :multiple="true"
      />
    </div>
    <div>
      <label>Item validation</label>
      <pim-filter
        :fields="fields"
        entryType="item"
      />
      <div />
      <label>Variation validation</label>
      <pim-filter
        :fields="fields"
        entryType="variation"
      />
      <div />
      <label>SKU validation</label>
      <pim-filter
        :fields="fields"
        entryType="sku"
      />
    </div>
    <div class="pim-function-actions">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        @click="submit"
      >
        Submit changes
      </button>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="discard"
      >
        Discard changes
      </button>
    </div>
  </div>
</template>
<script>
module.exports = {
  mixins: [
    require('./pim-helper.js'),
  ],
  props: {
    entry: { type: Object },
    users: { type: Array },
    fields: { type: Array },
    storefronts: { type: Array },
  },
  data() {
    return {
      name: this.entry.name,
      autoApprove: this.entry.attrs.is_auto_approve,
      daysToComplete: this.entry.attrs.days_required_to_complete,
      collaborators: {
        id: this.entry.id,
        type: this.entry.type,
        childrenType: 'collaborator',
      },
    };
  },
  methods: {
    submit() {
      if (Object.isFrozen(this.entry.attrs)) {
        this.$set(this.entry, 'attrs', _.clone(this.entry.attrs));
      }
      this.$set(this.entry.attrs, 'days_required_to_complete', parseInt(this.daysToComplete));
      this.$set(this.entry.attrs, 'is_auto_approve', this.autoApprove);
      this.$set(this.entry, 'name', this.name);
    },
    discard() {

    },
  },
  mounted() {
    this.loadChildren(this.collaborators);
  },
  watch: {
    entry() {
      this.collaborators = {
        id: this.entry.id,
        type: this.entry.type,
        childrenType: 'collaborator',
      };
      this.loadChildren(this.collaborators);
    },
  },
};
</script>
<style>
.pim-function select {
    display: block;
}
</style>
