var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gp-table-dates" },
    [
      _c(
        "div",
        [
          _c("feather-icon", { attrs: { name: "target" } }),
          _vm._v(" "),
          _c(
            "label",
            [_c("l10n", { attrs: { value: "Optimization run:" } })],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "gp-table-timeframe" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.getOptimizationName(_vm.optimizationId) || "...")
              )
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                key: _vm.optimizationsControlKey,
                staticClass: "form-control form-control-sm",
                domProps: { value: _vm.optimizationId },
                on: {
                  change: function($event) {
                    return _vm.$emit("change", $event.target.value)
                  }
                }
              },
              [
                !_vm.getOptimizationName(_vm.optimizationId)
                  ? _c("option", { domProps: { value: _vm.optimizationId } }, [
                      _vm._v("...")
                    ])
                  : _c("option", { attrs: { value: "" } }, [_vm._v("...")]),
                _vm._v(" "),
                _vm._l(_vm.optimizations, function(ref) {
                  var id = ref.id
                  var name = ref.name
                  return _c("option", { domProps: { value: id } }, [
                    _vm._v(_vm._s(name))
                  ])
                })
              ],
              2
            )
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function($event) {
                  _vm.showOptimizationPicker = true
                }
              }
            },
            [
              _c("feather-icon", {
                attrs: {
                  name: _vm.showOptimizationPicker ? "book-open" : "book"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("gp-data", {
        ref: "optimizations",
        attrs: {
          id: "optimizations",
          stream: _vm.stream,
          groups: ["optimizations", "reference-date", "search"],
          source: {
            dims: ["item", "target_price_zone as price_zone"],
            links: [
              {
                linkName: "optimization",
                sourceName: "optimization",
                columnPairs: [
                  {
                    srcColumn: "item",
                    dstColumn: "item"
                  },
                  {
                    srcColumn: "price_zone",
                    dstColumn: "price_zone"
                  }
                ]
              }
            ]
          },
          dims: ["optimization.optimization_run_id"],
          vals: [
            "optimization.strategy_name",
            "optimization.create_user",
            "optimization.create_time"
          ],
          vars: _vm.vars,
          sort: [-4],
          expand: "optimization"
        },
        model: {
          value: _vm.optimizationsReport,
          callback: function($$v) {
            _vm.optimizationsReport = $$v
          },
          expression: "optimizationsReport"
        }
      }),
      _vm._v(" "),
      _vm.showOptimizationPicker && _vm.bounds
        ? _c(
            "my-dialog",
            {
              attrs: { xlarge: true, title: "Select optimization run" },
              on: {
                close: function($event) {
                  _vm.showOptimizationPicker = false
                }
              }
            },
            [
              _c("gp-runs", {
                attrs: {
                  locale: _vm.locale,
                  bounds: _vm.bounds,
                  username: _vm.username,
                  referenceDate: _vm.referenceDate,
                  pastTimeframe: _vm.pastTimeframe,
                  futureTimeframe: _vm.futureTimeframe,
                  stream: _vm.stream,
                  groups: ["optimizations", "search"],
                  source: {
                    filter0: "date == `" + _vm.referenceDate + "`",
                    dims: ["item", "target_price_zone as price_zone"],
                    links: [
                      {
                        linkName: "optimization",
                        sourceName: "optimization",
                        columnPairs: [
                          {
                            srcColumn: "item",
                            dstColumn: "item"
                          },
                          {
                            srcColumn: "price_zone",
                            dstColumn: "price_zone"
                          }
                        ]
                      }
                    ]
                  },
                  filter2:
                    "true || optimization.create_user == '" +
                    _vm.username +
                    "'",
                  expand: "optimization",
                  dims: _vm.optimizationDims,
                  vals: _vm.optimizationVals,
                  vars: _vm.vars,
                  sort: _vm.optimizationSort,
                  take: _vm.optimizationTake,
                  sections: _vm.optimizationSections,
                  metrics: _vm.metrics,
                  formats: _vm.formats,
                  formulas: _vm.formulas,
                  timeframes: _vm.timeframes
                },
                on: {
                  click: function($event) {
                    _vm.$emit("change", $event)
                    _vm.showOptimizationPicker = false
                  }
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }